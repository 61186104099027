import { SERVICE_DISRUPTION_FLAG, useFlags } from '@hc-claims/experiments';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import { getLogger } from '@hc-claims/utilities';
import { SERVICE_DISRUPTION_ROUTE } from '../router/constants';
import { ProductParams } from '../products/air/cfar/routes/params';

export const useServiceDisruptionFlagCheck = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const { contractId } = useParams() as ProductParams;

  const logger = getLogger('ServiceDisruptionPage');

  useEffect(() => {
    if (
      flags[SERVICE_DISRUPTION_FLAG] &&
      !matchPath(SERVICE_DISRUPTION_ROUTE, location.pathname)
    ) {
      logger.info(
        `ServiceDisruptionPage rendered with contract ID: ${contractId}`,
      );
      navigate(SERVICE_DISRUPTION_ROUTE);
    }
  }, [flags, navigate, location, logger, contractId]);
};

import { Button, Card, Flex, Heading, Text } from 'theme-ui';
import { useCallback, useMemo } from 'react';
import { generateContactUsUrl } from '@hc-claims/utilities';
import { usePartnerContext } from '@hc-claims/ui';
import { usePartnerTranslations } from '../../hooks';
import AlertIcon from '../../../assets/alert.svg?react';
import { useStore } from '../../store';

export const ServiceDisruptionPage = () => {
  const { t } = usePartnerTranslations();
  const { partnerId } = usePartnerContext();
  const locale = useStore((state) => state.locale);

  const contactUsUrl = useMemo(
    () => generateContactUsUrl(partnerId, locale),
    [partnerId, locale],
  );

  const openContactUs = useCallback(() => {
    window.open(contactUsUrl, '_blank');
  }, [contactUsUrl]);

  return (
    <div data-testid="service-disruption-page">
      <Card sx={{ paddingTop: 40, textAlign: 'center' }}>
        <AlertIcon data-testid="alert-icon" />
        <Heading as="h2" sx={{ mb: 3, mt: 3 }}>
          {t?.('service_disruption_title')}
        </Heading>

        <Flex sx={{ flexDirection: 'column', gap: 4, alignItems: 'center' }}>
          <Text>{t?.('please_try_again_later')}</Text>

          <Button
            onClick={openContactUs}
            sx={{
              variant: 'buttons.secondary',
            }}
          >
            {t?.('contact_us')}
          </Button>
        </Flex>
      </Card>
    </div>
  );
};
